@font-face {
  font-family: "Aeonik";
  src: url("https://db.onlinewebfonts.com/t/12ff62164c9778917bddb93c6379cf47.eot");
  src: url("https://db.onlinewebfonts.com/t/12ff62164c9778917bddb93c6379cf47.eot?#iefix")
      format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/12ff62164c9778917bddb93c6379cf47.woff2")
      format("woff2"),
    url("https://db.onlinewebfonts.com/t/12ff62164c9778917bddb93c6379cf47.woff")
      format("woff"),
    url("https://db.onlinewebfonts.com/t/12ff62164c9778917bddb93c6379cf47.ttf")
      format("truetype"),
    url("https://db.onlinewebfonts.com/t/12ff62164c9778917bddb93c6379cf47.svg#Aeonik Pro Regular")
      format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Aeonik";
  src: url("https://db.onlinewebfonts.com/t/362636484f8ad521fec5a297fdc0ab12.eot");
  src: url("https://db.onlinewebfonts.com/t/362636484f8ad521fec5a297fdc0ab12.eot?#iefix")
      format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/362636484f8ad521fec5a297fdc0ab12.woff2")
      format("woff2"),
    url("https://db.onlinewebfonts.com/t/362636484f8ad521fec5a297fdc0ab12.woff")
      format("woff"),
    url("https://db.onlinewebfonts.com/t/362636484f8ad521fec5a297fdc0ab12.ttf")
      format("truetype"),
    url("https://db.onlinewebfonts.com/t/362636484f8ad521fec5a297fdc0ab12.svg#Aeonik Pro Bold")
      format("svg");
  font-weight: 700;
  font-style: normal;
}
